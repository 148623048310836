import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ScrollToTopRoute from "./history";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import { Resume } from "./pages/Resume";
import ProtectedRoute from "./ProtectedRoute";

const Routes1 = () => (
  <Switch>
    <ScrollToTopRoute path="/" exact component={Home} />
    <ScrollToTopRoute path="/Home" exact component={Home} />
    <ScrollToTopRoute path="/About" exact component={About} />
    <ScrollToTopRoute path="/Resume" exact component={Resume} />
    <ScrollToTopRoute path="/Contact" exact component={Contact} />
    <ScrollToTopRoute path="/Projects" exact component={Projects} />
    <ProtectedRoute path="/dashboard" exact component={Dashboard} />
    <Route
      path="/login"
      exact
      render={(props) =>
        sessionStorage.getItem("token") ? (
          <Redirect to="/dashboard" />
        ) : (
          <LoginPage {...props} />
        )
      }
    />
  </Switch>
);

export default Routes1;