import styled, { css } from "styled-components";

export const DashboardWrapExcel = styled.div`
  max-width: 2500px;
  margin: 75px auto 0px auto;
  height: 730px;
  overflow: auto;

  padding: 40px 20px;

  color: #fff;

  position: relative;
  min-height: 100vh;
  /* Update the text color to black */

  .logout-button {
    background: transparent;
    border: none;
    color: #fff;
    padding: 10px;
    box-shadow: inset 0 0 1px 1.5px #fff;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    top: 50px;
    &:hover {
      background: #1d6ba0;
    }

    @media only screen and (max-width: 685px) {
      right: 4%;
    }
  }

  .welcome-message {
    position: absolute;
    top: 0px;
    font-size: 0.8rem;
  }
  .welcome-message {
    position: absolute;
    top: 0px;
    font-size: 0.8rem;
  }

  @media (max-width: 500px) {
    margin-top: 100px;
    .welcome-message {
      font-size: 1rem;
    }
  }
  &:nth-child(4) {
    margin-bottom: 10px;
  }

  .grid-checkbox {
    appearance: none;
    background-color: #fff;

    font: inherit;
    color: currentColor;
    width: 1.8em;
    height: 1.8em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;

    display: grid;

    place-content: center;

    &:before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em blue;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
      transform: scale(1);
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 160px 0.5fr 0.6fr 1fr 180px;
  grid-gap: 5px;
  /* Add background color and border styles for GridContainer */
  background-color: #fff;

  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  /* For screens larger than 580px, display the grid with 5 columns */
  @media (min-width: 581px) {
    /* Add another row for the headings */
    .heading {
      grid-column: span 1;
      display: block; /* Show the headings for larger screens */
    }
  }

  /* For screens smaller than 581px, display the grid with 1 column */
  @media (max-width: 580px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    width: 98%;
    height: auto;
    margin: 10px auto;
    /* Hide the headings for smaller screens */
    .heading {
      display: none;
    }
  }
`;

export const HeadingItem = styled.div`
  /* Add background color and padding to the heading cells */
  background-color: #f2f2f2;
  padding: 10px;
  font-weight: bold;
  font-size: calc(10px + 0.2vw);
  color: #333;
  border-bottom: 1px solid #ccc;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GridItem = styled.div`
  padding: 15px;
  line-height: 1.3em;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  font-size: 14px;

  /* Added CSS transition to animate the expansion */
  transition: max-height 0.3s ease-in-out;
  max-height: ${({ expanded }) => (expanded ? "500px" : "100px")};
  min-width: ${({ expanded }) => (expanded ? "569px" : "100%")};
  cursor: pointer;
  letter-spacing: 0.5px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .current-date {
    margin-right: 5px !important;
  }
  @media screen and (min-width: 600px) {
    .current-date {
      display: none;
      margin-right: 20px !important;
    }
  }
`;

export const DeleteButton = styled.button`
  margin-bottom: 10px;
  text-shadow: 1px 1px 0 #222;
  padding: 5px 10px;
  /* Adjust the DeleteButton styles */
  background-color: #206942;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.6s ease-in-out;

  .logout-button {
    background: transparent;
    border: none;
    color: #fff;
    padding: 10px;
    box-shadow: inset 0 0 1px 1.5px #fff;
    border-radius: 4px;
    position: absolute;
    right: 215px;
    top: 120px;
    &:hover {
      background: #1d6ba0;
    }

    @media only screen and (max-width: 1600px) {
      right: 4%;
    }
  }
`;

// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\
// ************************************ PJDUX STYLES BELOW **************************************\\

export const DashboardWrapPjdux = styled.div`
  max-width: 2500px;
  margin: 75px auto 0px auto;
  height: 730px;
  overflow: auto;

  padding: 40px 20px;

  color: #fff;

  position: relative;
  min-height: 100vh;

  .logout-button {
    background: transparent;
    border: none;
    color: #fff;
    padding: 10px;
    box-shadow: inset 0 0 1px 1.5px #fff;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    top: 50px;
    &:hover {
      background: #1d6ba0;
    }

    @media only screen and (max-width: 685px) {
      right: 4%;
    }
  }

  .welcome-message {
    position: absolute;
    top: 0px;
    font-size: 0.8rem;
  }

  @media (max-width: 500px) {
    margin-top: 100px;
    .welcome-message {
      font-size: 1rem;
    }
  }
  &:nth-child(4) {
    margin-bottom: 10px;
  }

  .grid-checkbox {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.8em;
    height: 1.8em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &:before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em blue;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
      transform: scale(1);
    }
  }
`;

export const GridContainerPjdux = styled.div`
  display: grid;
  color: #fff;

  grid-template-columns: 160px 0.5fr 0.6fr 1fr 180px;
  grid-gap: 5px;

  /* For screens larger than 580px, display the grid with 5 columns */
  @media (min-width: 581px) {
    /* Add another row for the headings */
    .heading {
      grid-column: span 1;
      display: block; /* Show the headings for larger screens */
    }
  }

  /* For screens smaller than 581px, display the grid with 1 column */
  @media (max-width: 580px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    width: 98%;
    height: auto;

    margin: 10px auto;
    /* Hide the headings for smaller screens */
    .heading {
      display: none;
    }
  }
`;

export const HeadingItemPjdux = styled.div`
  padding: 10px;

  box-shadow: inset 0 0 1px 2px #fff;
  font-weight: bold;
  font-size: calc(10px + 0.2vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GridItemPjdux = styled.div`
  padding: 12px;
  line-height: 1.3em;
  height: 300px;
  background-color: #1d6ba0;
  box-shadow: inset 0 0 0.5px 2px #1a5f8e;
  border-radius: 2px;
  overflow: auto;
  font-size: calc(10px + 0.4vw);

  /* Added CSS transition to animate the expansion */
  transition: max-height 0.3s ease-in-out;
  max-height: ${({ expanded }) => (expanded ? "500px" : "100px")};
  min-width: ${({ expanded }) => (expanded ? "569px" : "100%")};
  cursor: pointer;
  letter-spacing: 0.5px;

  .current-date {
    margin-right: 4px !important;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (min-width: 600px) {
    .current-date {
      display: none;
    }
  }
`;

export const DeleteButtonPjdux = styled.button`
  margin-bottom: 10px;
  text-shadow: 1px 1px 0 #222;
  padding: 5px 10px;
  background-color: #f58723;
  color: white;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.6s ease-in-out;
`;
