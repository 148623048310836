import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // Use useHistory instead of useNavigate
import LoginFormWrapper from "../styled-containers/LoginFormWrapper";

const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:5000";

console.log("Base URL:", baseURL);
const LoginForm = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "user") {
      setUsername(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${baseURL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        sessionStorage.setItem("token", token);
        history.push("/dashboard"); // Use history.push to navigate
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Login failed");
      }
    } catch (error) {
      console.error("Error submitting login form:", error);
      setError("An error occurred while submitting the form");
    }
  };

  return (
    <LoginFormWrapper>
      <h2>Hello Pjdux, Please Login</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label>Username</label>
        <input
          placeholder="Enter Your Username or Email"
          value={username}
          onChange={handleChange}
          name="user"
          type="text"
        />
        <label>Password</label>
        <input
          placeholder="Enter Your Password"
          value={password}
          onChange={handleChange}
          type="password"
          name="password"
        />
        {error && <div className="error-message">{error}</div>}
        <input type="submit" className="submit-input" value="Login" />
      </form>
    </LoginFormWrapper>
  );
};

export default LoginForm;