import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Header from "../containers/Header";
import AdminDashboard from "../components/AdminDashboard";
import HomeWrap from "../styled-containers/HomeWrap";

const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:5000";

const Dashboard = () => {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    // <-- Corrected handleLogout function (NO async)
    // Clear the token from session storage
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
    history.push("/"); // Redirect to the login page after logout
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Use sessionStorage since that’s where the token is stored
        const token = sessionStorage.getItem("token");
        if (!token) {
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Authentication failed:", error);
      } finally {
        setLoading(false);
      }
    };
  
    checkAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <HomeWrap>
      <Header primary />
      <AdminDashboard handleLogout={handleLogout} />{" "}
      {/* Correct handleLogout passed */}
    </HomeWrap>
  );
};

export default Dashboard;
