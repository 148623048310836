import React from "react";
import styled from "styled-components";
import HashBanksLogo from "../assets/hashBanksLogo.png";
import Header from "../containers/Header";
import MockDataLogo from "../assets/mock-data-logo.png";
import Profile from "../assets/profile9.jpg";
import ffpLogo from "../assets/ffp-logo-cms-site.svg";
import ZoiiLogo from "../assets/zoii-max-site.png";

const ProjectsContainer = styled.div`
  background: linear-gradient(135deg, #1e1e2f 0%, #2d2d44 100%);
  min-height: 100vh;
  padding: 80px 24px;
  color: #f0f0f0;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 32px;
  max-width: 1400px;
  margin: 60px auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-8px);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const ProjectContent = styled.div`
  padding: 24px;
`;

const ProjectTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #fff;
`;

const ProjectRole = styled.p`
  font-size: 14px;
  color: #ffd700; // Changed to a modern cyan
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;
const ProjectDescription = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;
`;

const ProjectLink = styled.a`
  text-decoration: none;
`;

const ViewButton = styled.button`
  width: 100%;
  padding: 12px;
  background: transparent;
  border: 1px solid #ffd700; // Changed to cyan
  color: #ffd700
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 210, 211, 0.1); // Updated hover color
    transform: translateY(-2px);
  }
`;

const PageTitle = styled.h1`
  font-size: 48px;
  text-align: center;
  margin-bottom: 40px;
  color: #FF1177;
  font-weight: 700;
  animation: neon 1.5s ease-in-out infinite alternate;

  span {
    display: inline-block;
  }

  .letter-flicker {
    animation: letterFlicker 4s linear infinite;
  }

  .letter-color {
    animation: colorShift 2s ease-in-out infinite alternate;
  }

  @keyframes neon {
    from {
      text-shadow: 
        0 0 5px #FF1177,
        0 0 10px #FF1177,
        0 0 20px #FF1177,
        0 0 40px #FF1177;
    }
    to {
      text-shadow: 
        0 0 5px #FF1177,
        0 0 10px #BC13FE,
        0 0 20px #BC13FE,
        0 0 40px #BC13FE,
        0 0 60px #BC13FE;
    }
  }

  @keyframes letterFlicker {
    0%, 89%, 93%, 95%, 100% {
      opacity: 1;
      text-shadow: 
        0 0 5px #BC13FE,
        0 0 10px #BC13FE,
        0 0 20px #BC13FE,
        0 0 40px #BC13FE;
    }
    90%, 92%, 94%, 96% {
      opacity: 0.3;
      text-shadow: none;
    }
  }

  @keyframes colorShift {
    from {
      color: #FF1177;
      text-shadow: 
        0 0 5px #FF1177,
        0 0 10px #FF1177,
        0 0 20px #FF1177;
    }
    to {
      color:rgb(255, 106, 235);
      text-shadow: 
        0 0 5px #BC13FE,
        0 0 10px #BC13FE,
        0 0 20px #BC13FE;
    }
  }
`;


export default () => (
  <ProjectsContainer>
    <Header primary />
    <PageTitle>
  <span>F</span>
  <span className="letter-flicker">e</span>
  <span>a</span>
  <span>t</span>
  <span className="letter-color">u</span>
  <span>r</span>
  <span>e</span>
  <span>d</span>
  <span> </span>
  <span>P</span>
  <span className="letter-flicker">r</span>
  <span>o</span>
  <span>j</span>
  <span className="letter-color">e</span>
  <span>c</span>
  <span>t</span>
  <span>s</span>
</PageTitle>
    
    <ProjectsGrid>
      {[
        {
          image: ZoiiLogo,
          title: "Zoii Clean & Cozy",
          role: "Developer/Designer",
          description: "Developed a responsive e-commerce site using React.js and Firebase with Stripe integration for secure payments.",
          link: "https://shopzoii.com/"
        },
        {
          image: ffpLogo,
          title: "Fact Checking Tool",
          role: "Developer/Designer",
          description: "A tool for fact-checking various media types including text, URLs, and videos.",
          link: "https://ffp-tool.vercel.app/"
        },
        {
          image: HashBanksLogo,
          title: "#Banq",
          role: "Developer/Designer",
          description: "A Jam-Stack app for creating and organizing hashtags in customizable banks.",
          link: "https://hashbanks.vercel.app/"
        },
        {
          image: MockDataLogo,
          title: "Friendly Data Generator",
          role: "Developer/Designer",
          description: "An intuitive app for developers to quickly generate JSON mock data.",
          link: "https://friendly-data-generator.vercel.app/"
        },
        {
          image: Profile,
          title: "PJDUX",
          role: "Developer/Designer",
          description: "Personal website built with React.js, Node.js, and MySQL database.",
          link: "/"
        }
      ].map((project, index) => (
        <ProjectCard key={index} style={{padding: `${project.title === "Fact Checking Tool" ? "20px" : "0"}`}}>
          <ProjectImage 
            src={project.image} 
            alt={project.title}
            style={(project.title === "PJDUX") ? {
              borderRadius: "0",
              height: "240px",

              objectFit: "cover"
            } : project.title === 'Fact Checking Tool' ? {
              borderRadius: "0",
              height: "240px",
              objectFit: "contain"


              
            } : {
            
            }}
          />
          <ProjectContent>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectRole>{project.role}</ProjectRole>
            <ProjectDescription>{project.description}</ProjectDescription>
            <ProjectLink href={project.link} target="_blank">
              <ViewButton>View Project</ViewButton>
            </ProjectLink>
          </ProjectContent>
        </ProjectCard>
      ))}
    </ProjectsGrid>
  </ProjectsContainer>
);